import { Module } from "vuex";
import { State } from "@/store/state";
import { RedpackDocs, RedpackItem } from "@/interface";
import { requestPost } from "@/request/http/http";
import { redpackApi } from "@/request/http";
import { timestamp10, uuid } from "@/utils/util";
import { store } from "@/store";
import { useCustomRouter } from "@/router/custRouter";
import { cloneDeep } from "lodash";
import { ROUTE_LOGIN } from "@/router/paths";
export interface RedpackState {
  showFloating: boolean;
  redpackList: RedpackItem[] | null;
  redpackDocs: RedpackDocs | null;
  showRedpackPopup: boolean;
  popupData: any;
}

export const redpack: Module<RedpackState, State> = {
  namespaced: true,
  state: {
    showFloating: true,
    redpackList: null,
    redpackDocs: null,
    showRedpackPopup: false,
    popupData: null,
  },
  getters: {
    getDocs(state) {
      return state.redpackDocs;
    },
    getPopupData(state) {
      return state.popupData;
    },
    showRedpackPopup(state) {
      return state.showRedpackPopup;
    },
    listForFloating: (state) =>
      state.redpackList
        ? state.redpackList.filter((v) => v.showPopup === "1")
        : [],
    showRedpackFloating: (state, getters) =>
      state.showFloating && getters.listForFloating.length > 0,
    getInTimePeriodFloatingRedpack: (_, getters) => (now: number) => {
      // 获取floating在时间段内的第一个红包
      return getters.listForFloating.find(
        (v: RedpackItem) =>
          now >= Number(v.collectionStartTime) &&
          now <= Number(v.collectionEndTime)
      );
    },
    listForLeftBar: (state) => {
      // 这里进行一下排序
      if (state.redpackList) {
        return state.redpackList
          .filter((v) => v.showSide === "1")
          .sort(
            (a, b) =>
              Number(a.collectionStartTime) - Number(b.collectionStartTime)
          );
      } else {
        return [];
      }
    },
    showRedpackOnLeftBar: (_, getters) => getters.listForLeftBar.length > 0,
    getInTimePeriodLeftBarRedpack: (_, getters) => (now: number) => {
      // 获取laftBar在时间段内的第一个红包
      return getters.listForLeftBar.find(
        (v: RedpackItem) =>
          now >= Number(v.collectionStartTime) &&
          now <= Number(v.collectionEndTime)
      );
    },
    getWillTimeForLeftBarRedpack: (_, getters) => (now: number) => {
      // 获取laftBar未来可领红包的倒计时时间
      const nowHasRedpack = getters.getInTimePeriodLeftBarRedpack(now);
      if (nowHasRedpack) {
        // 目前有可领的红包
        return 0;
      } else {
        // 可能在页面停留很久，然后数据没有更新。所以要判断collectionStartTime是否超过当前时间
        const redpack = getters.listForLeftBar.find(
          (v: RedpackItem) => now < Number(v.collectionStartTime)
        );

        if (redpack) {
          return Number(redpack.collectionStartTime) - now;
        } else {
          return 0;
        }
      }
    },
  },
  actions: {
    getRedpackList({ commit }) {
      requestPost(redpackApi.list, null, (response) => {
        if (Array.isArray(response)) {
          console.log("获取到红包雨列表");
          commit("saveRedpackList", response);
        }
      });
      requestPost(redpackApi.docs, null, (response) => {
        if (response) {
          commit("saveRedpackDocs", response);
        }
      });
    },
    // 点击红包的检查
    checkRedpack({ commit, getters }, payload) {
      // 1floating 2leftBar
      let nowRedpack: any;
      if (payload === 1) {
        nowRedpack = getters.getInTimePeriodFloatingRedpack(timestamp10());
      } else if (payload === 2) {
        nowRedpack = getters.getInTimePeriodLeftBarRedpack(timestamp10());
      }

      if (nowRedpack) {
        // 存在当前时间段可以领的红包
        if (!store.getters.loginSuccess) {
          // 未登录, 进登录页
          useCustomRouter(null).push(ROUTE_LOGIN.path);
          return;
        }

        const params = {
          id: nowRedpack.id,
          uuid: uuid(),
        };
        requestPost(
          redpackApi.check,
          params,
          (response) => {
            if (response.status) {
              commit("goShowRedpackPopup", cloneDeep(nowRedpack));
            }
          },
          () => {
            commit("goShowRedpackPopup", null);
          }
        );
      } else {
        commit("goShowRedpackPopup", null);
      }
    },
  },
  mutations: {
    reset(state) {
      state.showRedpackPopup = false;
      state.popupData = null;
    },
    saveRedpackList(state, payload) {
      state.redpackList = payload;
    },
    saveRedpackDocs(state, payload) {
      state.redpackDocs = payload;
    },
    hideFloating(state) {
      state.showFloating = false;
    },
    closeRedpackPopup(state) {
      state.showRedpackPopup = false;
    },
    goShowRedpackPopup(state, payload) {
      state.popupData = payload;
      state.showRedpackPopup = true;
    },
  },
};

import { Module } from "vuex";
import { State } from "@/store/state";
import { ActivityItem, ActivityListItem } from "@/interface";
import { useCustomRouter } from "@/router/custRouter";

export interface ActivitiesState {
  leftActList: ActivityItem[];
  activityList: ActivityListItem[];
  ativoBoxInfo: any | null; //活跃度宝箱列表
  taskActList: any[]; //任务左侧分类
}

export const activities: Module<ActivitiesState, State> = {
  namespaced: true,
  state: {
    leftActList: [],
    activityList: [],
    ativoBoxInfo: null,
    taskActList: [],
  },
  getters: {
    activities: (state) => (categoryId: string) => {
      if (categoryId == "0") return state.activityList;
      else return state.activityList.filter((v) => v.categoryId == categoryId);
    },
    leftActList: (state) => state.leftActList,
    ativoBoxInfo: (state) => state.ativoBoxInfo,
    taskActList: (state) => state.taskActList,
  },
  actions: {
    toActivityDetail(store, id: string) {
      if (!id) return;
      const activity = store.state.activityList.filter(
        (v: ActivityListItem) => v.id == id
      )[0];
      if (!activity) return;
      // giftType 1跳充值活动 2跳打码活动 3跳救济金 4跳自定义活动
      if (activity?.giftType == 1 || activity?.giftType == 2) {
        useCustomRouter(null).push({
          path: "/activity/purchaseOrBet",
          query: {
            categoryId: activity.categoryId,
            description: activity.description,
            finishTime: activity.finishTime,
            giftType: activity.giftType,
            id: activity.id,
            img: activity.img,
            name: activity.name,
            startTime: activity.startTime,
          },
        });
        return;
      }
      if (activity?.giftType == 3) {
        useCustomRouter(null).push({
          path: "/activity/aims",
          query: {
            categoryId: activity.categoryId,
            // description: activity.description,
            // finishTime: activity.finishTime,
            id: activity.id,
            img: activity.img ? activity.img : "",
            // name: activity.name,
            // startTime: activity.startTime,
          },
        });
        return;
      }
      if (activity.giftType == 4) {
        useCustomRouter(null).push({
          name: "ActivityInfoCustom",
          params: {
            categoryId: activity.categoryId,
            id: activity.id,
            img: activity.img ? activity.img : "",
          },
        });
      }
      if (activity.giftType == 5) {
        useCustomRouter(null).push({
          name: "ActivityAgentTreaSureChest",
          query: {
            categoryId: activity.categoryId,
            description: activity.description,
            finishTime: activity.finishTime,
            id: activity.id,
            name: activity.name,
            startTime: activity.startTime,
          },
        });
      }
    },
  },
  mutations: {
    saveActivityList(state, payload) {
      state.activityList = payload.list;
    },
    saveleftActList(state, payload) {
      state.leftActList = payload.list;
    },
    saveAtivoBoxInfo(state, payload) {
      state.ativoBoxInfo = payload.data;
    },
    saveTaskActList(state, payload) {
      state.taskActList = payload.list;
    },
  },
};
